import * as React from "react"
import { Helmet } from "react-helmet"

import GooglePlay from "../components/icon_google_play"
import AppStore from "../components/icon_app_store"
import IconCounter from "../images/icon_counter.svg"
import IconMyAd3yah from "../images/icon_myad3yah.svg"
import IconNoAds from "../images/icon_no_ads.svg"
import IconNotifications from "../images/icon_notifications.svg"
import IconAllahNames from "../images/icon_allahNames.svg"
import IconAppearance from "../images/icon_appearance.svg"
import IconFav from "../images/icon_fav.svg"
import IconReminders from "../images/icon_reminders.svg"

import LightMobileImage from "../images/mobile_light.svg"
import DarkMobileImage from "../images/mobile_dark.svg"

import Envelop from "../images/envelop.svg"
import Twitter from "../components/icon_twitter"
import Instagram from "../components/icon_instagram"

import "../styles/index.scss"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
`
// data
const links =
{
  android: "https://noorathkar.com/play",
  ios: "https://noorathkar.com/ios",
  twitter: "https://twitter.com/noorathkar",
  instagram: "https://instagram.com/noorathkar"
}

var html;

if (typeof window !== `undefined`) {
  html = window.document.querySelector('html');
  html.dataset.theme = `theme-light`;
}


// markup
const IndexPage = () => {
  return (
    <div className="ModalContext Bg">
      <div role="button" className="Circle" onClick={() => {
        switch (html.dataset.theme) {
          case `theme-dark`:
            html.dataset.theme = `theme-light`;
            break;
          case `theme-light`:
            html.dataset.theme = `theme-dark`;
            break;
        }
      }}>
      </div>
      <Container>
        <div className="Content">
          <Helmet>
            <title>نُور | أذكار وأدعية</title>
            <meta name="title" content="نور أذكار وأدعية" />
            <meta
              name="description"
              content="يوفّر تطبيق نُور تجربة قراءة مريحة، خالية من الإعلانات، لأذكار حصن المسلم والأدعية الصحيحة من الكتاب والسنة."
            />
          </Helmet>
          <title>نُور</title>
          <div className="Logo LightModeLogo"></div>

          <div className="IntroSection MaxWidth">
            <div className="MobileImage">
              <img className="Dark" src={DarkMobileImage} />
              <img className="Light" src={LightMobileImage} />
            </div>
            <div className="Description">
              <p className="main-text">يوفّر تطبيق نُور تجربة قراءة مريحة، خالية من الإعلانات، لأذكار حصن المسلم والأدعية الصحيحة من الكتاب والسنة.</p>
              <h3>حمّل التطبيق الآن</h3>
              <p className="secondary-text">متوفر على متجر تطبيقات أندرويد و iOS، متاح أيضًا على ساعات Apple</p>
              <div className="DownloadButtons">
                <a target="_blank" href={links.android} className="Button">
                  <span><GooglePlay></GooglePlay></span>
                  Google Play
                </a>
                <a target="_blank" href={links.ios} className="Button">
                  <span><AppStore></AppStore></span>
                  App Store
                </a>
              </div>
            </div>

          </div>
          <div className="FeaturesSection MaxWidth">

            <h1>أهم ميزات التطبيق</h1>
            <div className="Features">
              <div className="FeatureBox">
                <img src={IconAppearance} />
                <h4>خيارات للمظهر</h4>
                <p>ميزة الوضع الليلي لقراءة مريحة، التحكم بإعدادات الخط، وغيرها.</p>
              </div>
              <div className="FeatureBox">
                <img src={IconReminders} />
                <h4>تنبيهات أذكار الصباح والمساء</h4>
                <p>إضافة وضبط منبه لأذكار الصباح والمساء بسهولة.</p>
              </div>

              <div className="FeatureBox">
                  <img src={IconNotifications} />
                <h4>إشعارات تذكيرية</h4>
                <p>إشعارات بآيات قرآنية، أدعية، وأحاديث منوعة.</p>
              </div>
              <div className="FeatureBox">
                <img src={IconAllahNames} />
                <h4>صفحة لأسماء الله الحُسنى</h4>
                <p>قائمة خاصة بأسماء الله الحسنى ومعانيها مرتبطة بالأدعية الموجودة في التطبيق.</p>
              </div>
            </div>
            <div className="Features">
              <div className="FeatureBox">
                <img src={IconMyAd3yah} />
                <h4>قسم لأدعيتك الخاصة</h4>
                <p>يمكنك إضافة أدعية خاصة بك، ستكون محفوظة للعودة لقراءتها في أي وقت.</p>
              </div>

              <div className="FeatureBox">
                <img src={IconCounter} />
                <h4>سَبحة رقميّة</h4>
                <p>ردد أي ذكر باستخدام السبحة على جوالك أو بساعة Apple الخاصة.</p>
              </div>
              <div className="FeatureBox">
                <img src={IconFav} />
                <h4>صفحة المُفضلة</h4>
                <p>قم بتفضيل أي دعاء، ذكر أو حتى من أدعيتك الخاصة لوصول أسهل وأسرع.</p>
              </div>
              <div className="FeatureBox">
                <img src={IconNoAds} />
                <h4>خالٍ من الإعلانات</h4>
                <p>لقراءة هادئة وبدون مشتتات.</p>
              </div>

            </div>
            <p className="secondary-text">وأكثر! اكتشف العديد من الميزات والإضافات المستمرة</p>
          </div>
          <div className="ContactSection ContactSectionBg">
            <div className="ContactSectionContent MaxWidth">
              <img src={Envelop} />
              <div>
                <h3>تواصل معنا</h3>
                <p>نحرص على تنفيذ مقترحاتكم وملاحظاتكم وحل المشاكل التي تواجهكم في التطبيق، راسلونا عبر البريد الإلكتروني:</p>
                <a href="mailto:noorathkarapplication@gmail.com?subject=اقتراح&body=السلام عليكم،">noorathkarapplication@gmail.com</a>
              </div>
            </div>
            <div className="Footer">
              <div className="row" style={{ maxWidth: "40vw", height: "25x" }}>
                <div className="smallpadding"><a target="__blank" href={links.twitter} className="icon"><Twitter size="25" ></Twitter></a></div>
                <div className="smallpadding"><a target="__blank" href={links.instagram} className="icon"><Instagram size="25" ></Instagram></a></div>
                <div className="smallpadding "><a target="__blank" href={links.ios} className="icon"><AppStore size="25" ></AppStore></a></div>
                <div className="smallpadding "><a target="__blank" href={links.android} className="icon"><GooglePlay size="25" ></GooglePlay></a></div>
              </div>
              <p>جميع الحقوق محفوظة لفريق نُور © ٢٠٢١</p>
            </div>

          </div>
        </div>
      </Container>
    </div>
  )
}

export default IndexPage

